export default {
  BUTTON_PRIMARY_CLASS: 'cm_button btn',
  BUTTON_SECONDARY_CLASS: 'cm_button btn outline',

  CLEAR_TEXT: 'Clear',

  SHOW_LESS: '- Less',
  SHOW_MORE: '+ More',

  SEARCH_BOX_PLACEHOLDER: 'Search...',

  GARAGE_TITLE: '',

  PRODUCT_REPEATER_CLASS: 'product-loop',
};
