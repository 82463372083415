import shopifyGenericDefaults from '../shopify-generic/config.js';

const AutoSyncKey = 'buy-wheels-today';

shopifyGenericDefaults.Widgets.find((w) => w.name === 'Garage').location = {
  selector: '#cm-garage',
  class: 'cm_garage-desktop',
};

shopifyGenericDefaults.Widgets.find((w) => w.name === 'MobileGarage').location = {
  selector: '#cm-mobile-garage',
  class: 'cm_garage-mobile',
};

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  window.theme.Collection(document.getElementById('itemsBlock'));
  window.CompareProducts();
}

window.Convermax.GA4addToCartSubmit = (product) => {
  window.gtag('event', 'add_to_cart', {
    currency: 'USD',
    value: product.price,
    items: [
      {
        ['item_name']: product.title,
        ['item_id']: product.id,
        quantity: 1,
      },
    ],
  });
};

window.Convermax.mountedPackagesAddToCartWithRedirect = (fitmentType, productId, variantId) => async (e) => {
  const { href } = window.location;

  const redirectPath =
    href.includes('WheelProductID') || href.includes('TireProductID')
      ? '/cart'
      : fitmentType.includes('Wheel')
        ? `/collections/all-tires/?WheelProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
        : fitmentType.includes('Tire')
          ? `/collections/wheels?TireProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
          : null;

  if (redirectPath) {
    e.preventDefault();
    e.stopPropagation();

    const response = await ajaxAddToCart(variantId);

    if (response.ok) {
      window.location.replace(redirectPath);
    } else {
      // check if cart contains product
      // need this case to force redirect when client has this product in cart
      const cartResponse = await ajaxGetCartItems();
      const parsedCardResponse = await cartResponse.json();

      if (parsedCardResponse?.items?.some((i) => i.variant_id === +variantId)) {
        window.location.replace(redirectPath);
      } else {
        console.error(response);
      }
    }
  }
};

async function ajaxAddToCart(id, quantity = 4) {
  const formData = {
    items: [
      {
        id,
        quantity,
      },
    ],
  };

  return fetch(`${window.Shopify.routes.root}cart/add.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
}

async function ajaxGetCartItems() {
  return fetch(`${window.Shopify.routes.root}cart.js`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

const AfterInit = () => {
  window.Convermax.openVisualizationDialogwithGA4 = (wheelMpn) => {
    window.Convermax.openVisualizationDialog(wheelMpn);
    window.gtag?.('event', 'autosync_interaction', {
      ['event_category']: 'AutoSync',
      ['event_label']: 'Visualizer Interaction',
      value: wheelMpn,
    });
  };
};

const onVerifyFitmentResponseReceived = ({ Items }) => {
  const fits = Items[0].fitsTheVehicle;
  const packageButton = window.document.querySelector('.add-to-cart-package');

  if (fits) {
    if (packageButton) {
      packageButton.style.display = 'block';
    }
  } else {
    packageButton.style.display = 'none';
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  AfterInit,
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    onVerifyFitmentInitResponseReceived: onVerifyFitmentResponseReceived,
    onVerifyFitmentResponseReceived,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) => !['SearchResult', 'FacetBarWheels', 'FacetBarTires'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'WheelTireVehicleInfo',
      autoSyncVisualization: {
        apiKey: AutoSyncKey,
        config: {
          height: '100px',
        },
      },
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'AutoSyncVisualizationDialog',
      apiKey: AutoSyncKey,
      template: 'fitmentSearch/autoSyncVisualizationDialog',
    },
  ],
};
